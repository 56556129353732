import { FC } from "react";
import { PageContainer } from "../../components/page/Page";
import { useAppSelector } from "../../store";
import { UserSettings } from "./UserSettings";

export const ProfilePage: FC = () => {
  const { user } = useAppSelector((s) => s.app);
  return (
    <PageContainer>
      <div className="column flex1 paddingLg gapXlg">
        <h2>{user?.name}'s Profile</h2>

        <div className="flex1 column justifyEnd">
          <UserSettings userId={user?._id} />
        </div>
      </div>
    </PageContainer>
  );
};
