import { AppTextField } from "../../../components/Input/TextField/TextField";
import { SettingsWindow } from "../UserSettings";

export const AccountSettings: React.FC<SettingsWindow> = ({
  data,
  onChange,
}) => {
  return (
    <div className="column gapXlg">
      <div className="column gapSm">
        <span className="inputLabel">Email</span>
        <AppTextField
          type="email"
          value={data.username!}
          disabled
          onChange={(ev) => onChange({ username: ev.target.value })}
          placeholder="gigi_hadid@gmail.com"
        />
        <span className="subTitle">
          Changing an email is currently not allowed
        </span>
      </div>
      <div className="column gapSm">
        <span className="inputLabel">Name</span>
        <AppTextField
          value={data.name!}
          onChange={(ev) => onChange({ name: ev.target.value })}
        />
      </div>
      <div className="column gapSm">
        <span className="inputLabel">Age</span>
        <AppTextField
          type="number"
          value={"" + data.age!}
          onChange={(ev) => onChange({ age: +ev.target.value })}
        />
      </div>
    </div>
  );
};
