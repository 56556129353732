import React, { useState } from "react";

interface ImageWithFallbackProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  fallback?: string;
}

export const Image: React.FC<ImageWithFallbackProps> = ({
  src,
  fallback = "",
  alt,
  ...props
}) => {
  const [imgSrc, setImgSrc] = useState(src);

  const handleError = () => {
    setImgSrc(fallback);
  };

  return <img {...props} src={imgSrc} alt={alt} onError={handleError} />;
};
