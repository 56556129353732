import { ProductsGrid } from "../../../../components/ProductsGrid/ProductsGrid";
import { useInfiniteProductSearch } from "../../../../hooks/query/product/useProuctsSearch";
import { useFlattenInfiniteQuery } from "../../../../hooks/query/useFlattenInfinite";

export const SaleView = () => {
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteProductSearch({
      filters: [{ key: "prev_price", value: { gt: 1 } as any }],
    });

  const products = useFlattenInfiniteQuery(data);

  return (
    <div className="paddingLg">
      <ProductsGrid
        products={products}
        isLoading={isLoading}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={!!hasNextPage}
      />
    </div>
  );
};
