import { produce } from "immer";
import { omit } from "lodash";
import React, { useEffect, useState } from "react";
import { LookalikeUploadView } from "../../../../pages/Lookalike/LookalikeProducts";
import { EventWizardView } from "../../../../pages/eventWizard/EventWizardView";
import { ListSavingSelection } from "../../../../pages/lists/cmps/listSaveSelection/ListSavingSelection";
import { LookbookWindow } from "../../../../pages/lookbook/LookbookDetails/LookbookDetails";
import { CollectionView } from "../../../../pages/search/cmps/collectionDetails/CollectionView";
import { PersonalizedWeightsView } from "../../../../pages/search/cmps/personalizedWeights/PersonalizedWeights";
import { socketService } from "../../../../services/mock/socket";
import { FiltersView } from "../../../filters/FiltersView";
import { AppWindowData } from "./types";

export const AppWindowPortal: React.FC = () => {
  const [windows, setWindows] = useState<AppWindowData[]>([]);

  const [isClosingVisible, setIsClosingVisible] = useState(false);
  useEffect(() => {
    socketService.on("windowOpen", "appWindow", (data: AppWindowData) => {
      setWindows((prev) => {
        return [...prev, data];
      });
    });
    socketService.on("cleanup", "footer", () => {
      setWindows([]);
    });
    return () => {
      socketService.off("windowOpen", "appWindow");
      socketService.off("cleanup", "footer");
    };
  }, []);

  const onCloseWindow = async () => {
    setIsClosingVisible(true);
    setTimeout(() => {
      setWindows(
        produce((draft) => {
          draft.pop();
        })
      );
      setIsClosingVisible(false);
    }, 300);
  };

  return (
    <>
      {windows.map((w, index) => (
        <DynamicWindow
          {...w}
          onClose={onCloseWindow}
          isOpen={index !== windows.length - 1 || !isClosingVisible}
        />
      ))}
    </>
  );
};

type DynamicWindowProps = AppWindowData & {
  onClose: () => void;
  isOpen: boolean;
};

const DynamicWindow: React.FC<DynamicWindowProps> = ({
  id,
  data,
  ...commonProps
}) => {
  const [delayedOpen, setDelayedOpenToFirstMountAndThenOpenWithAnimation] =
    useState(false);

  useEffect(() => {
    setDelayedOpenToFirstMountAndThenOpenWithAnimation(commonProps.isOpen);
  }, [commonProps.isOpen]);

  const props = {
    ...omit(commonProps, "isOpen"),
    isOpen: delayedOpen,
  };

  switch (id) {
    case "SaveToList":
      return <ListSavingSelection productId={data.productId} {...props} />;
    case "CollectionView":
      return <CollectionView data={data} {...props} />;
    case "SearchWeights":
      return <PersonalizedWeightsView {...props} {...data} />;
    case "imgLookalikeView":
      return <LookalikeUploadView {...data} {...props} />;
    case "eventWizard":
      return <EventWizardView {...props} />;
    case "filters":
      return <FiltersView onSubmit={data.onSubmit} {...props} />;
    case "lookbookWindow":
      return <LookbookWindow {...props} data={data} />;
    default:
      return <></>;
  }
};
