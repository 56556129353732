import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { User } from "../../@types/entities/user";

import { HOME_BANNERS } from "../../pages/home/views/home/HomeBanners";
import { appActions } from "../../store/app/slice";
import usePreloadImages from "../ui/usePreloadImages";
import { useHomePage } from "./home/useHomePage";
import { useLists } from "./lists/useLists";
import { useProductFeatureFilters } from "./product/useProductFilters";

export const useAppInit = (user: User | null) => {
  const dispatch = useDispatch();
  // useHomeFeed();
  const { data } = useHomePage();
  const { data: filters } = useProductFeatureFilters();
  const { data: list } = useLists();
  const [didLoad] = usePreloadImages(HOME_BANNERS.map((s) => s.thumbnail));
  useEffect(() => {
    if (!!(data && filters && list && didLoad)) {
      dispatch(appActions.setDidLoadHome());
    }
  }, [data, filters, list, didLoad]);
  return !!(data && filters && list && didLoad);
};
