import Tag from "@mui/icons-material/LocalOfferOutlined";
import { filter, keyBy, values } from "lodash";
import { useState } from "react";
import { GigiTitle } from "../../../../components/gigiTitle/GigiTitle";
import { PageContainer } from "../../../../components/page/Page";
import { ProductsGrid } from "../../../../components/ProductsGrid/ProductsGrid";
import { WindowTitle } from "../../../../components/slidingWindow/cmps/WindowTitle";
import { SlidingWindow } from "../../../../components/slidingWindow/SlidingWindow";
import { useSuggestedBrands } from "../../../../hooks/query/brands/useBrands";
import { useInfiniteProductSearch } from "../../../../hooks/query/product/useProuctsSearch";
import { useFlattenInfiniteQuery } from "../../../../hooks/query/useFlattenInfinite";
import { useFilterActions } from "../../../../hooks/system/useFilterActions";
import { useDelayedClose } from "../../../../hooks/ui/useDelayedClose";
import { useViewCaller } from "../../../../hooks/ui/useWindowCaller";
import { ReactComponent as Arrow } from "../../../../styles/icons/ArrowRight.svg";
import { ReactComponent as Dollar } from "../../../../styles/icons/Dollar.svg";
import { adaptFilterOptions } from "../../../Lookalike/LookalikePage";
import { ResultsActions } from "../../../search/SearchPage";
import { BrandPreview } from "../home/BrandsPreview";
import s from "./BrandsView.module.scss";

const INITIAL_WEIGHTS = {
  formal: 0,
  chic: 0,
  edgy: 0,
};

export const BrandsView: React.FC = () => {
  const { data } = useSuggestedBrands({ limit: 25 });
  const [callView] = useViewCaller();
  const { onOpen, onClose, isOpen, data: windowData } = useDelayedClose();
  const [filters, setFilters] = useState<any>([]);
  const [weights, setWeights] =
    useState<Record<string, number>>(INITIAL_WEIGHTS);
  const {
    filtersOptions,
    adaptedStoreActiveFilters,
    updateFilter,
    setStoreFilters,
  } = useFilterActions();

  const {
    data: productPages,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteProductSearch(
    {
      filters: [...filters, { key: "brand_code", value: windowData?.data }],
      limit: 25,
      weights,
    },
    !!windowData?.data
  );

  const updateFilterChange = async (key: string, value: string) => {
    const updatedFilters = updateFilter(key, value, filters ?? []);
    setFilters(updatedFilters);
  };

  const updateWeightChange = (weights: Record<string, number>) => {
    setWeights(weights);
  };
  const products = useFlattenInfiniteQuery(productPages);
  const brands = useFlattenInfiniteQuery(data);

  const resetState = () => {
    setFilters([]);
    setWeights(INITIAL_WEIGHTS);
  };

  const onCloseBrand = () => {
    resetState();
    onClose();
  };

  return (
    <>
      <div style={{ padding: "0 16px" }}>
        <GigiTitle
          title="Discover New Brands"
          description="Gigi helps you discover new brands and styles you might love."
        />
      </div>
      <div className={s.container}>
        {brands.map((b, index) => (
          <div
            style={{ animationDelay: `${index / 30}s` }}
            onClick={() => onOpen({ name: b.name, data: b._id })}
            className={s.brand}
          >
            <div className="column gapMd">
              <div className="alignCenter spaceBetween">
                <div className={s.titleHeader}>{b.name}</div>
                <Arrow />
              </div>
              <div
                style={{ color: "#737373", fontSize: "12px" }}
                className={"alignCenter gapSm"}
              >
                <div className="flex alignCenter">
                  <Dollar />
                  {[75, 200, 500].map((limit) =>
                    limit < b.avg ? <Dollar /> : null
                  )}
                </div>
                |
                <div className="alignCenter gapSm">
                  {b.tags &&
                    b.tags.map((t: string) => (
                      <div
                        style={{
                          fontSize: "12px",
                          color: "#737373",
                          gap: "2px",
                          fontWeight: 500,
                        }}
                        className="alignCenter "
                      >
                        <Tag fontSize="inherit" sx={{ color: "#737373" }} />
                        {t}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className={s.preview}>
              <BrandPreview onOpen={onOpen} brand={b} />
            </div>
          </div>
        ))}
      </div>
      <SlidingWindow
        isOpen={isOpen}
        onClose={() => onCloseBrand()}
        direction="right"
      >
        <PageContainer>
          <div className="column gapLg">
            <WindowTitle title={windowData?.name} />

            <ResultsActions
              personalizedCount={
                filter(values(weights), (w) => !!w)?.length ?? 0
              }
              filteredCount={filters.length}
              filtersProps={{
                options: adaptFilterOptions([
                  ...(filtersOptions?.features.filters ?? []),
                  ...(filtersOptions?.metadata.filters.filter(
                    (c) => c.key !== "brand"
                  ) ?? []),
                ]),
                activeFilters: keyBy(filters, "key"),
                onChange: updateFilterChange,
              }}
              onClickPersonalize={() =>
                callView({
                  id: "SearchWeights",
                  data: {
                    weightList: ["Formal", "Sexy", "Casual", "Flowy", "Chic"],
                    weights,
                    onChange: updateWeightChange,
                    payload: {
                      filters: [
                        ...filters,
                        { key: "brand_code", value: windowData?.data },
                      ],
                    },
                  },
                })
              }
            />

            <div className="sideMargin">
              <ProductsGrid
                products={products}
                isLoading={isLoading}
                isFetchingNextPage={!!isFetchingNextPage}
                fetchNextPage={fetchNextPage}
                hasNextPage={!!hasNextPage}
              />
            </div>
          </div>
        </PageContainer>
      </SlidingWindow>
    </>
  );
};
