import React from "react";
import { Navigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { AdminGuard, RouteGuard } from "../components/routeGuard/RouteGaurd";

export const withGaurd = (Fc: React.FC): React.FC => {
  const Guarded: React.FC = (props) => {
    return (
      <RouteGuard>
        <Fc />
      </RouteGuard>
    );
  };

  return Guarded;
};

export const Rerouted = () => {
  const [search] = useSearchParams();
  return <Navigate to={`home?${search.toString()}`} />;
};
export const withAdminGuard = (Fc: React.FC): React.FC => {
  const Guarded: React.FC = (props) => {
    return (
      <AdminGuard>
        <Fc />
      </AdminGuard>
    );
  };

  return Guarded;
};
