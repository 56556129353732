import { find, shuffle } from "lodash";
import { Carousel } from "../../../../components/carousels/Carousel";
import { useLookbooks } from "../../../../hooks/query/lookbook/useLookbook";
import { useViewCaller } from "../../../../hooks/ui/useWindowCaller";
import { LookbookDetails } from "../../../lookbook/LookbookDetails/LookbookDetails";
import { ViewPreviewTemplate } from "./previewTemaplate/PreviewTemplate";

export const LookbooksPreview: React.FC<{ onOpenView: () => void }> = ({
  onOpenView,
}) => {
  const [callWindow] = useViewCaller();
  const { data } = useLookbooks();
  return (
    <ViewPreviewTemplate
      title="Inspo From The Stars"
      cta="See More"
      onClickCta={() => onOpenView()}
    >
      <Carousel
        settings={{ perView: 1.2, enablePagination: false }}
        items={
          shuffle(data?.items)
            .slice(0, 4)
            .map((l) => ({
              title: l.name,
              description: l.description.split(".")[0],
              id: l._id,
              element: <LookbookBanner url={l.mainImg} />,
            })) ?? []
        }
        onClickItem={(id) =>
          callWindow({
            id: "lookbookWindow",
            data: {
              lookbook: find(
                data?.items,
                (l) => l._id === id
              ) as LookbookDetails,
            },
          })
        }
      />
    </ViewPreviewTemplate>
  );
};

const LookbookBanner: React.FC<{ url: string }> = ({ url }) => {
  return (
    <div style={{ padding: "0 16px" }} className="flex justifyCenter">
      <div>
        <img
          src={url}
          alt=""
          style={{
            borderRadius: "6px",
            aspectRatio: 9 / 10,
            objectFit: "cover",
            objectPosition: "top",
          }}
        />
      </div>
    </div>
  );
};
