import { noop } from "lodash";
import { GroupsLayout } from "../categories/Categories";

export const EventsView = () => {
  return (
    <GroupsLayout
      onClickGroup={noop}
      groups={[
        "Black Tie",
        "Holiday Party",
        "Special Event",
        "Getaway",
        "Night Out",
        "Wedding",
        "Cocktail",
        "Winter Formal",
        "Birthday",
        "For The Bride",
      ].map((s) => ({
        src: "https://cdn-img.prettylittlething.com/7/4/3/9/74390f5798687cb7702c06f5632196f5792da299_cnh8591_1.jpg",
        name: s,
      }))}
    />
  );
};
