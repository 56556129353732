import { Dictionary, filter, isEmpty, keyBy, noop, values } from "lodash";
import { ReactNode, useContext, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { FilterUnit } from "../../@types/entities/product.types";
import { AppContext } from "../../App";
import { FiltersPreview } from "../../components/filters/filtersPreview/FiltersPreview";
import { useHeaderActions } from "../../components/header/useHeaderActions";
import { PageContainer } from "../../components/page/Page";
import { Pill } from "../../components/pill/Pill";
import { SearchHeader } from "../../components/searchHeader/SearchHeader";
import { useFilterActions } from "../../hooks/system/useFilterActions";
import { useViewCaller } from "../../hooks/ui/useWindowCaller";
import { useAppSelector } from "../../store";
import { ReactComponent as Filter } from "../../styles/icons/Filter.svg";
import { ReactComponent as PersonalizeIcon } from "../../styles/icons/Personalize.svg";
import { CategoriesView } from "../home/views/categories/Categories";
import { adaptFilterOptions } from "../Lookalike/LookalikePage";
import s from "./SearchPage.module.scss";
import { ProductsView } from "./views/productsView/ProductsView";
import { SearchPills } from "./views/searchView/SearchView";
const initialWieghts = {
  Formal: 0,
  Casual: 0,
  Sexy: 0,
  Flowy: 0,
  Shiny: 0,
};

export const SearchPage: React.FC = () => {
  const { onSearch } = useHeaderActions();
  return (
    <PageContainer>
      <WithSearch>
        <div className="column gapXlg paddingLg">
          <SearchPills onSearch={onSearch} />
          <CategoriesView />
        </div>
      </WithSearch>
    </PageContainer>
  );
};

export const WithSearch: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { searchTerm, user, filters } = useAppSelector(({ app }) => app);
  const { isMobile } = useContext(AppContext);
  const { onSearch } = useHeaderActions();
  const [windowName, setWindow] = useState<"allFilters" | string | null>(null);
  const stickeyHeader = useRef<HTMLDivElement>(null);
  const {
    filtersOptions,
    adaptedStoreActiveFilters,
    updateFilter,
    setStoreFilters,
  } = useFilterActions();
  const navigate = useNavigate();
  const [callView] = useViewCaller();

  const [weights, setWeights] =
    useState<Record<string, number>>(initialWieghts);

  if (!user) {
    return null;
  }

  const isSearchMode = searchTerm || !isEmpty(adaptedStoreActiveFilters);

  const updateFilterChange = async (key: string, value: string) => {
    const updatedFilters = updateFilter(key, value);
    setStoreFilters(updatedFilters);
  };

  const updateWeightChange = (weights: Record<string, number>) => {
    setWeights(weights);
    setWindow(null);
  };

  const onBack = () => {
    onSearch("");
    setStoreFilters([]);
  };

  const Actions = (
    <ResultsActions
      onCLickFilters={() => {
        callView({
          id: "filters",
          data: {
            onSubmit: noop,
          },
        });
      }}
      personalizedCount={filter(values(weights), (w) => !!w)?.length ?? 0}
      filteredCount={filters.length}
      filtersProps={{
        options: adaptFilterOptions([
          ...(filtersOptions?.features.filters ?? []),
          ...(filtersOptions?.metadata.filters ?? []),
        ]),
        activeFilters: keyBy(filters, "key"),
        onChange: updateFilterChange,
      }}
      onClickPersonalize={() =>
        callView({
          id: "SearchWeights",
          data: {
            weightList: ["Formal", "Sexy", "Casual", "Flowy", "Chic"],
            weights,
            onChange: updateWeightChange,
            payload: { filters: filters, term: searchTerm },
          },
        })
      }
    />
  );

  return (
    <>
      <SearchHeader
        onSearch={onSearch}
        onBack={onBack}
        isSearchMode={!!isSearchMode}
        stickyBottomCmp={isSearchMode && Actions}
      />

      <div className={s.searchPage}>
        {isSearchMode && <div style={{ padding: "8px 0px" }}>{Actions}</div>}

        {!searchTerm && isEmpty(adaptedStoreActiveFilters) && children}

        <ProductsView
          isVisible={!!searchTerm || !isEmpty(adaptedStoreActiveFilters)}
          weights={weights}
        />
      </div>
    </>
  );
};

export const ResultsActions: React.FC<{
  onClickPersonalize?: () => void;
  onCLickFilters?: () => void;
  personalizedCount: number;
  filteredCount?: number;
  filtersProps: {
    activeFilters: Dictionary<FilterUnit>;
    options: FilterUnit[];
    onChange: (key: string, value: any) => void;
    onClickAllFilters?: () => void;
  };
}> = ({
  onClickPersonalize,
  onCLickFilters,
  personalizedCount,
  filteredCount,
  filtersProps,
}) => {
  return (
    <div className={`${s.filtersContainer}  no-swiping alignCenter`}>
      {onCLickFilters && (
        <div
          style={{
            height: "30px",
            minWidth: "30px",
            maxWidth: "30px",
            border: "thin solid #00000010",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: filteredCount ? "#1F2029" : "white",
          }}
          onClick={onCLickFilters}
        >
          <div className={`icon withFill ${filteredCount ? "light" : "grey"}`}>
            <Filter width={"18px"} height={"14px"} />
          </div>
        </div>
      )}
      {onClickPersonalize && (
        <div className="relative">
          {!!personalizedCount && <Indicator count={personalizedCount} />}
          <Pill
            // id="personalizeButton"
            onClick={onClickPersonalize}
            size="sm"
            type="ai"
            // className="flex1"
          >
            <div className="gapMd alignCenter icon withFill dark">
              <PersonalizeIcon width={"14px"} height={14} />
              Style
            </div>
          </Pill>
        </div>
      )}
      <FiltersPreview {...filtersProps} />
    </div>
  );
};

const Indicator: React.FC<{
  forType?: "primary" | "secondary";
  count: number;
}> = ({ forType = "primary", count }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "-3px",
        right: "-3px",
        padding: "6px",
        borderRadius: "50%",
        backgroundColor: "white",
        border: "thin solid #00000010",
        // transform: "translate(50%,-50%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* {!!count && count} */}
    </div>
  );
};
