import { ReactNode } from "react";
import { PriceRangeValues } from "../../../components/filters/filterCmpMap/PriceRangeValues";
import { SettingsWindow } from "../UserSettings";

export const PriceSettings: React.FC<SettingsWindow> = ({ data, onChange }) => {
  return (
    <div className="column gapXlg">
      <div className="column alignCenter gapMd">
        <h4>Comfortable Price Range</h4>
        <span className="subTitle center">
          Gigi won’t restrict your options to this range but will use it to
          refine recommendations that align with your preferences.
        </span>
      </div>
      <PriceRangeValues
        options={[10, 10000]}
        onChange={(value) =>
          onChange({ priceRangeScale: [value.gt, value.lt] })
        }
        selectedOptions={data.priceRangeScale ?? [10, 10000]}
      />
    </div>
  );
};

const Item: React.FC<{
  children: ReactNode;
  isSelected: boolean;
  onClick: () => void;
}> = ({ children, isSelected, onClick }) => {
  return (
    <div
      onClick={() => onClick()}
      style={{
        fontSize: "14px",
        backgroundColor: isSelected ? "black" : "white",
        border: "thin solid #00000010",
        color: isSelected ? "white" : "black",
      }}
      className="justifyCenter paddingLg"
    >
      {children}
    </div>
  );
};
