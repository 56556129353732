import { keys, omit } from "lodash";
import { useState } from "react";
import { BasicQuery } from "../../../../@types/query";
import { ProductsGrid } from "../../../../components/ProductsGrid/ProductsGrid";
import { Button } from "../../../../components/button/Button";
import { IntersectionObserverCmp } from "../../../../components/intersector/Intersector";
import { SlidingWindow } from "../../../../components/slidingWindow/SlidingWindow";
import { WindowTitle } from "../../../../components/slidingWindow/cmps/WindowTitle";
import { CollectionViewInterface } from "../../../../components/slidingWindow/types/app/types";
import { useInfiniteProductSearch } from "../../../../hooks/query/product/useProuctsSearch";
import { useFlattenInfiniteQuery } from "../../../../hooks/query/useFlattenInfinite";
import { useViewCaller } from "../../../../hooks/ui/useWindowCaller";
import { ReactComponent as PersonalizeIcon } from "../../../../styles/icons/Personalize.svg";
import s from "./CollectionView.module.scss";

export const CollectionView: React.FC<{
  data: CollectionViewInterface["data"];
  onClose: () => void;
  isOpen: boolean;
}> = ({ isOpen, onClose, data }) => {
  const [payload, setPayload] = useState<BasicQuery>(data.payload);
  const [callView] = useViewCaller();
  const {
    data: products,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteProductSearch({
    ...omit(payload, "tags"),
  });
  const flattendedProducts = useFlattenInfiniteQuery(products);

  const onChangeWeights = (weights: Record<string, number>) => {
    setPayload((prev) => ({ ...prev, weights }));
  };

  const ButtonPersonalize = () => (
    <Button
      onClick={() =>
        callView({
          id: "SearchWeights",
          data: {
            weights: data.payload.weights!,
            weightList: keys(data.payload.weights),
            onChange: onChangeWeights,
            payload: {
              filters: data.payload.filters,
              term: data.payload.term,
            },
          },
        })
      }
    >
      <div className="gapMd alignCenter">
        <PersonalizeIcon />
        Personalize
      </div>
    </Button>
  );
  return (
    <SlidingWindow
      isOpen={isOpen}
      onClose={onClose}
      height={"100%"}
      direction="right"
    >
      <WindowTitle title={data.title} />
      <div className="flex1 column gapLg sideMargin">
        <IntersectionObserverCmp
          className={s.container}
          intersectionClassName={s.pinned}
          isInView={() => {}}
          isOutsideView={() => {}}
        >
          {data.payload.weights && <ButtonPersonalize />}
        </IntersectionObserverCmp>

        <ProductsGrid
          hasNextPage={!!hasNextPage}
          fetchNextPage={fetchNextPage}
          isLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
          products={flattendedProducts}
        />
      </div>
    </SlidingWindow>
  );
};
