import { FC, ImgHTMLAttributes, useRef, useState } from "react";
import { MiniProduct } from "../../../../@types/entities/product.types";
import { Image } from "../../../../components/image/Img";
import { analytics } from "../../../../services/api/analytics";
import { useAppSelector } from "../../../../store";
import { ReactComponent as Like } from "../../../../styles/icons/Like.svg";
import { ReactComponent as Liked } from "../../../../styles/icons/Liked.svg";
import { camelCaseToCapitalized } from "../../../../utils/str";
import styles from "./productCard.module.scss";
export interface ProductCardProps {
  product: MiniProduct;
  onClick?: (product: MiniProduct) => void;
  className?: string;
  sumVersion?: boolean;
  disablePreviewSwipe?: boolean;
  onFailedImg?: () => void;
  // TODO:have an object that controls what you see instead of many props
  priceVersion?: boolean;
  hideLike?: boolean;
  onToggleLike?: (id: string) => void;
  size?: "sm" | "reg";
}
export const ProductCard: FC<ProductCardProps> = ({
  product,
  className,
  onClick,
  onFailedImg,
  sumVersion,
  disablePreviewSwipe,
  hideLike,
  onToggleLike,
  priceVersion,
  size = "reg",
}) => {
  const observer = useRef<IntersectionObserver>();
  const [isVisible, setIsVisible] = useState(false);
  const { likedProducts } = useAppSelector((store) => store.app);

  const onClickCard = async () => {
    onClick && onClick(product!);
    try {
      await analytics.productView(product._id!);
    } catch (error) {}
  };

  const getPrice = () => {
    if (!product.price) return <></>;
    if (!product.prev_price || product.prev_price <= product.price)
      return <div className={styles.price}>${product.price.toFixed(2)}</div>;
    return (
      <div className="alignCenter gapMd">
        <div className={`${styles.price} ${styles.prev}`}>
          ${product.prev_price.toFixed(2)}
        </div>
        <div className={`${styles.price} ${styles.current}`}>
          ${product.price.toFixed(2)}
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={`${styles.product}  ${styles[size]} pressable ${
          className ?? ""
        }`}
        onClick={() => onClickCard()}
        style={{ position: "relative" }}
      >
        {onToggleLike && (
          <div
            onClick={(ev) => {
              ev.stopPropagation();
              onToggleLike(product._id!);
            }}
            className={styles.like}
          >
            {likedProducts.includes(product._id!) ? (
              <Liked width={16} height={16} />
            ) : (
              <Like fill="#2d2d2d" width={16} height={16} />
            )}
          </div>
        )}
        <div
          onClick={(ev) => {}}
          // ref={imageRef}
          className={styles.imgPlaceholder}
        >
          {/* {isVisible && ( */}
          <>
            {/* {!disablePreviewSwipe && <ImgSwiper imgs={product.imgUrls} />}
            {disablePreviewSwipe && <img src={product.imgUrls[0]} alt="" />} */}

            <Image
              src={product.imgUrls[0]}
              fallback={
                product.parsing_path ??
                product.feature_parse_path ??
                product.seg_path
              }
              alt=""
              loading="lazy"
            />
          </>
          {/* )} */}
        </div>
        <div className={styles.details}>
          {(sumVersion || !priceVersion) && (
            <div style={{ overflow: "hidden" }} className="flex flex1">
              <div className={styles.brandName}>
                {camelCaseToCapitalized(product.brand)}
              </div>
            </div>
          )}

          {(!sumVersion || priceVersion) && getPrice()}
        </div>
      </div>
    </>
  );
};

export const Img: React.FC<
  ImgHTMLAttributes<HTMLImageElement> & { isSlowLoad?: boolean }
> = ({ isSlowLoad, ...props }) => {
  return (
    <>
      <img {...props} loading="lazy" />
    </>
  );
};
