import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import React, { ReactNode } from "react";
import s from "./Pill.module.scss";
export const Pill: React.FC<{
  children: ReactNode;
  onClick: () => void;
  isSelected?: boolean;
  size?: "sm" | "md" | "lg";
  type?: "secondary" | "naked" | "ai";
}> = ({ children, size = "md", onClick, isSelected, type = "" }) => {
  return (
    <div
      onClick={onClick}
      className={`${s.pill} ${s[size]} ${isSelected ? s.selected : ""} ${
        s[type] ?? ""
      }`}
    >
      {children}
    </div>
  );
};

export const DropdownPill: React.FC<{
  children: ReactNode;
  onClick: () => void;
  isSelected?: boolean;
  size?: "sm" | "md" | "lg";
  type?: "secondary" | "naked";
}> = ({ children, ...props }) => {
  return (
    <Pill {...props}>
      <div className="alignCenter gapSm">
        {children}
        <div style={{ fontSize: "14px" }} className="alignCenter">
          <KeyboardArrowDownRoundedIcon fontSize="inherit" />
        </div>
      </div>
    </Pill>
  );
};
