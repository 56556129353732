import { useLocation, useNavigate } from "react-router";
import { useUsers } from "../../hooks/query/users/useUsers";
import { setCurrentUser } from "../../services/api/users/users.api";
import { useAppDispatch, useAppSelector } from "../../store";
import { appActions } from "../../store/app/slice";

export const useHeaderActions = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: users } = useUsers();
  const { user, searchTerm } = useAppSelector((app) => app.app);

  const onNavHome = () => {
    dispatch(appActions.setSearchTerm(""));
  };

  const onSelectUser = async (userId: string) => {
    await setCurrentUser(userId);
    const selectedUser = users?.find(({ _id }) => userId === _id);

    dispatch(appActions.onSelectUser(selectedUser));
  };
  const onSearch = (term: string) => {
    dispatch(appActions.setSearchTerm(term));
    if (location.pathname.startsWith("/search")) return;
    navigate("/search");
  };

  return { onNavHome, onSelectUser, onSearch, user, searchTerm, users };
};
