import styled from "@emotion/styled";
import { keyframes, Skeleton, SkeletonProps } from "@mui/material";

const COLOR = "#e4e4e4";
const gradientAnimation = keyframes`
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
`;
export const ProductSkeleton: React.FC<{
  repeat: number;
  sumVersion?: boolean;
}> = ({ repeat = 1, sumVersion }) => {
  return (
    <>
      {[...new Array(repeat)].map((_) => (
        <div id="productCard" className="flex flex1 column gapSm">
          <StyledSkeleton
            animation="wave"
            variant="rectangular"
            style={{
              aspectRatio: "11/18",
              width: "100%",
              borderRadius: "15px",
              height: "auto",
              backgroundColor: COLOR,
            }}
          />
          {!sumVersion && (
            <>
              <StyledSkeleton
                variant="text"
                animation="wave"
                style={{ width: "38%", backgroundColor: COLOR }}
              />
              <StyledSkeleton
                animation="wave"
                variant="text"
                style={{ width: "50%", backgroundColor: COLOR }}
              />
              <StyledSkeleton
                animation="wave"
                variant="text"
                style={{ width: "44%", backgroundColor: COLOR }}
              />
            </>
          )}
        </div>
      ))}
    </>
  );
};

export const StyledSkeleton = styled(Skeleton)<SkeletonProps>(() => ({
  "&.MuiSkeleton-root": {
    "&:after": {
      animationDuration: "0.6s",
      animationDelay: "0s",
      background: `linear-gradient(
        60deg,
        transparent,
        rgba(255, 255, 255, 0.4),
        transparent
      )`,
      backgroundSize: "200%",
    },
  },
}));
