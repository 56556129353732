import { random } from "lodash";
import { ReactNode } from "react";
import { GradientBackground } from "../../../pages/home/HomePage";
import { Button } from "../../button/Button";
import s from "./TextBanner.module.scss";

type Bg = "lavender" | "beige" | "ivory" | "pink" | "random" | "gradient";

interface Props {
  text: string;
  cta: string;
  onClick: () => void;
  description?: string;
  bg?: Bg;
}

export const BannerContainer: React.FC<{
  children: ReactNode;
  bg?: Bg;
}> = ({ children, bg }) => {
  const bgColors: Bg[] = ["beige", "ivory", "lavender", "pink"];

  if (bg == "gradient") {
    return (
      <GradientBackground>
        <div className={s.container}>{children}</div>
      </GradientBackground>
    );
  }
  return (
    <div
      className={`${s.container} ${
        bg ? s[bg === "random" ? bgColors[random(0, 2, false)] : bg] : ""
      }`}
    >
      {children}
    </div>
  );
};

export const BannerTextContent: React.FC<Props> = ({
  description,
  text,
  cta,
  onClick,
}) => {
  return (
    <div className="column gapXlg">
      <h2 style={{ textAlign: "center" }}>{text}</h2>
      {description && <h3 style={{ textAlign: "center" }}>{description}</h3>}
      <Button onClick={onClick}>{cta}</Button>
    </div>
  );
};

export const TextBanner: React.FC<Props> = ({ bg, ...props }) => {
  return (
    <BannerContainer bg={bg}>
      <BannerTextContent {...props}></BannerTextContent>
    </BannerContainer>
  );
};
