import { isEmpty } from "lodash";
import { ColorValues } from "../../../components/filters/filterCmpMap/colorValues/ColorValues";
import { SlidingWindow } from "../../../components/slidingWindow/SlidingWindow";
import { useFilterActions } from "../../../hooks/system/useFilterActions";
import { useDelayedClose } from "../../../hooks/ui/useDelayedClose";
import { toggleInList } from "../../../utils/array";
import { SettingsWindow } from "../UserSettings";
import { StyleSettingsTemplate } from "./StyleSettingsTemplate";

export const ColorsSettings: React.FC<SettingsWindow> = ({
  data,
  onChange,
}) => {
  const { onOpen, data: windoeColorData, onClose, isOpen } = useDelayedClose();
  const { filtersOptions } = useFilterActions();

  const state = {
    liked: {
      onChangeSelection: (val: string) =>
        onChange({ colors: toggleInList(data.colors ?? [], val) }),
      selected: data.colors,
      title: "My Colors",
    },
    disliked: {
      onChangeSelection: (val: string) =>
        onChange({
          dislikedColors: toggleInList(data.dislikedColors ?? [], val),
        }),
      selected: data.dislikedColors,
      title: "Disliked Colors",
    },
  };

  const getState = (title: string) => {
    if (title === state.liked.title) return state.liked;
    return state.disliked;
  };

  return (
    <StyleSettingsTemplate
      likedTitle={state.liked.title}
      dislikedTitle={state.disliked.title}
      likedItems={
        <ColorValues
          options={state.liked.selected ?? []}
          selectedOptions={[]}
          onChange={state.liked.onChangeSelection}
        />
      }
      dislikedItems={
        <ColorValues
          options={state.disliked.selected ?? []}
          selectedOptions={[]}
          onChange={state.disliked.onChangeSelection}
        />
      }
      selectionOptionsElement={
        <SlidingWindow
          isOpen={isOpen}
          onClose={onClose}
          title={windoeColorData?.name}
        >
          {!isEmpty(filtersOptions) && (
            <div className="paddingMd flex1 column overflowAuto">
              <ColorValues
                options={
                  filtersOptions?.features.filters.find(
                    (c) => c.key === "main_color_specific"
                  )?.value as string[]
                }
                selectedOptions={getState(windoeColorData?.name).selected ?? []}
                onChange={getState(windoeColorData?.name).onChangeSelection}
              />
            </div>
          )}
        </SlidingWindow>
      }
      onOpen={onOpen}
    />
  );
};
