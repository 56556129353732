import React from "react";
import { Pill } from "../../../../components/pill/Pill";
import { useViewCaller } from "../../../../hooks/ui/useWindowCaller";
import { ReactComponent as Dollar } from "../../../../styles/icons/Dollar.svg";
import { ReactComponent as Ai } from "../../../../styles/icons/ai.svg";
export type ActionOnProduct = "dupe" | "premium" | "eco" | "gigi" | "refine";

export interface ActionOnProductProps {
  actions: ActionOnProduct[];
  selectedAction?: ActionOnProduct;
  onAction: (action: ActionOnProduct, data?: any) => void;
}

export const ActionsOnProduct: React.FC<ActionOnProductProps> = ({
  onAction,
  actions,
  selectedAction,
}) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "8px",
        padding: "8px 0px 8px 8px",
        overflow: "auto",
      }}
    >
      {actions.map((action) => (
        <ActionFactory
          action={action}
          onAction={onAction}
          isSelected={action === selectedAction}
        />
      ))}
    </div>
  );
};

const ActionFactory: React.FC<
  Pick<ActionOnProductProps, "onAction"> & {
    action: ActionOnProduct;
    isSelected: boolean;
  }
> = ({ isSelected, onAction, action }) => {
  const commonProps = {
    isSelected,
  };

  switch (action) {
    case "dupe":
      return (
        <Pill {...commonProps} size="md" onClick={() => onAction("dupe")}>
          <Dollar width={"7px"} height={"14px"} />
          <span style={{ fontSize: "12px" }}>Dupe</span>
        </Pill>
      );
    case "premium":
      return (
        <Pill {...commonProps} onClick={() => onAction("premium")} size="md">
          <div className="flex">
            <Dollar width={"7px"} height={"14px"} />
            <Dollar width={"7px"} height={"14px"} />
          </div>
          <span style={{ fontSize: "12px" }}>Find premium</span>
        </Pill>
      );
    case "refine":
      return <RefineItem onAction={onAction} {...commonProps} />;

    case "gigi":
      return (
        <Pill
          {...commonProps}
          type="ai"
          size="md"
          onClick={() => onAction("gigi")}
        >
          <div className="gapSm alignCenter">
            <div className="icon withFill dark">
              <Ai width={14} height={14} />
            </div>
            <span style={{ fontSize: "12px" }}>Adjust item</span>
          </div>
        </Pill>
      );
    default:
      return <></>;
  }
};

export const RefineItem: React.FC<{
  onAction: ActionOnProductProps["onAction"];
  isSelected?: boolean;
}> = ({ isSelected, onAction }) => {
  const [callView] = useViewCaller();

  const onClick = () => {
    callView({
      id: "filters",
      data: {
        onSubmit: () => onAction("refine", null),
      },
    });
  };

  return (
    <Pill onClick={onClick} size="sm" type="secondary">
      <div className="icon withFill dark">Refine</div>
    </Pill>
  );
};
