import { Button } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { UserSignIn } from "../../pages/onboarding/OnboardPage";
import { authLogin, authVerify } from "../../services/api/admin/auth";
import {
  authForDevice,
  setCurrentUser,
} from "../../services/api/users/users.api";
import { useAppSelector } from "../../store";
import { appActions } from "../../store/app/slice";
import { Loader } from "../loader/Loader";
import { Modal } from "../Modal/Modal";

export const RouteGuard: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { user, didLoadHome } = useAppSelector((s) => s.app);
  const [searchParams, setSearchParams] = useSearchParams();
  const [auth, setAuth] = useState<boolean | undefined>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      // FOR NOW ONLY AUTO LOGIN IN PHONE
      if (user) return;
      const deviceId = searchParams.get("deviceId");
      if (!deviceId) {
        navigate(`/?${searchParams.toString()}`);
        return;
      }
      try {
        const authUser = await authForDevice(deviceId);
        await setCurrentUser(authUser._id);
        dispatch(appActions.onSelectUser(authUser));
        navigate(`/home?${searchParams.toString()}`);
      } catch (error) {
        setAuth(false);
        navigate(`/?${searchParams.toString()}`);
      }
    })();
  }, []);

  console.log(!user?._id || !didLoadHome);

  if (!user?._id || !didLoadHome) {
    // redirect("/");
    return <></>;
  }

  return <>{children}</>;
};
export const AdminGuard: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useAppSelector((s) => s.app);
  const [auth, setAuth] = useState<boolean | undefined>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        await authVerify();
        // dispatch(appActions.onSelectUser(authUser));
        setAuth(true);
      } catch (error) {
        setAuth(false);
      }
    })();
  }, [user?._id]);

  if (auth === undefined) return <Loader />;

  if (!user?._id || auth === false) {
    return (
      <Modal onClose={() => {}} open>
        <Login />
      </Modal>
    );
  }

  return <>{children}</>;
};

export const Login = () => {
  const [form, setForm] = useState<any>({ username: "", password: "" });
  const dispatch = useDispatch();
  const onLogin = async () => {
    try {
      const data = await authLogin(form);
      dispatch(appActions.onSelectUser(data.user));
    } catch (error) {}
  };

  return (
    <div className="column gapLg">
      <div style={{ width: "400px" }}>
        <div className="column relative">
          <UserSignIn onChange={(form) => setForm(form)} form={form} />
        </div>
        <Button onClick={() => onLogin()}>Log in</Button>
      </div>
    </div>
  );
};
