import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import { User } from "../../../@types/entities/user";
import {
  editUser,
  getPresetUsers,
  setAuthForDevice,
  setCurrentUser,
} from "../../../services/api/users/users.api";
import { useAppDispatch, useAppSelector } from "../../../store";
import { appActions } from "../../../store/app/slice";

export const useUsers = (payload?: { presets?: boolean }) =>
  useQuery({
    queryFn: async () => {
      try {
        if (payload?.presets) {
          const presets = await getPresetUsers();
          return presets.items;
        }
        return [];
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    queryKey: ["users", payload],
  });

export const useEditUserProfile = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { deviceId } = useAppSelector((s) => s.app);
  const [searchParams, setSearchparams] = useSearchParams();
  return useMutation({
    mutationKey: ["edit profile"],
    mutationFn: async (profile: User) => {
      const res = await editUser(profile);
      if (deviceId) {
        await setAuthForDevice(deviceId as string, res.profile._id!);
      }
      await setCurrentUser(res.profile._id!);
      dispatch(appActions.onSelectUser({ ...res.profile }));
      return res;
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });
};

export const useEditUserSettings = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { deviceId } = useAppSelector((s) => s.app);
  const [searchParams, setSearchparams] = useSearchParams();
  return useMutation({
    mutationKey: ["edit profile"],
    mutationFn: async (profile: User) => {
      const res = await editUser(profile);
      dispatch(appActions.updateUser({ ...res.profile }));
      return res;
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });
};
