import { isEqual } from "lodash";

export const toggleInList = (
  arr: any[],
  val: any,
  upsert: "asc" | "desc" = "asc"
) => {
  const updatedArray = [...arr];
  const valueIndex = arr.findIndex((el) => isEqual(el, val));
  if (valueIndex === -1) {
    if (upsert === "asc") return [...updatedArray, val];
    return [val, ...updatedArray];
  }
  updatedArray.splice(valueIndex, 1);
  return updatedArray;
};
