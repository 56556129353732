import { find } from "lodash";
import { Carousel } from "../../../../components/carousels/Carousel";
import { useViewCaller } from "../../../../hooks/ui/useWindowCaller";
import { Collection } from "../collections/types";

export const HOME_BANNERS: Collection[] = [
  {
    title: "Event Ready",
    thumbnail:
      "https://storage.googleapis.com/gigi_pics_public/creativeref_1011l94842.webp",
    payload: {
      term: "Cocktail dresses for a night out",
    },
  },
  {
    title: "Winter Ready",
    thumbnail:
      "https://creative.prf.hn/source/camref:1011l45XLD/creativeref:1100l99001",
    payload: {
      term: "dresses for the winter",
    },
  },
];
export const HomeBanners = () => {
  const [callWindow] = useViewCaller();
  return (
    <Carousel
      settings={{ enablePagination: true }}
      onClickItem={(id) =>
        callWindow({
          id: "CollectionView",
          data: find(HOME_BANNERS, ({ title }) => title === id) as any,
        })
      }
      items={HOME_BANNERS.map((details) => ({
        id: details.title,
        element: (
          <div
            style={{
              width: "100vw",
              aspectRatio: 4 / 5,
              position: "relative",
              display: "flex",
            }}
          >
            <img
              style={{ aspectRatio: 4 / 5, objectFit: "cover" }}
              src={details.thumbnail}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "center",
                position: "absolute",
                bottom: 0,
                left: 0,
                height: "30%",
                width: "100%",
                background:
                  "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.6))",
              }}
            >
              <div style={{ marginBottom: "24px" }} className="column gapLg">
                <p
                  style={{
                    color: "white",
                    textAlign: "center",
                    margin: 0,
                    fontSize: "13px",
                    fontWeight: 300,
                  }}
                >
                  Shop the collection
                </p>
                <h3 style={{ color: "white", textAlign: "center" }}>
                  {details.title}
                </h3>
              </div>
            </div>
          </div>
        ),
      }))}
    />
  );
};
