import { AppTextField } from "../../../../components/Input/TextField/TextField";
import { UserProfileBuilderForm } from "../../OnboardPage";
import pageStyles from "../../OnboardPage.module.scss";
import s from "./UserProfileForm.module.scss";

export const UserInfoForm: React.FC<{
  profile: UserProfileBuilderForm["profile"];
  onChange: (profile: UserProfileBuilderForm["profile"]) => void;
}> = ({ profile, onChange }) => {
  const onChangeForm = (key: string, value: string) => {
    onChange({ ...profile, [key]: value });
  };
  return (
    <>
      <div className={s.form}>
        <div className={s.field}>
          <div className="inputLabel">Name</div>
          <AppTextField
            placeholder="Insert name..."
            value={profile.name}
            containerClassName={pageStyles.onboardingInput}
            onChange={(ev) => onChangeForm("name", ev.target.value)}
          />
        </div>
        <div className={s.field}>
          <div className={"inputLabel"}>Age</div>
          <AppTextField
            placeholder="Insert age..."
            value={profile.age}
            containerClassName={pageStyles.onboardingInput}
            type="number"
            onChange={(ev) => onChangeForm("age", ev.target.value)}
          />
        </div>
      </div>
    </>
  );
};
