import React, { useMemo } from "react";
import { ProductsGrid } from "../../../../components/ProductsGrid/ProductsGrid";
import { useInfiniteProductSearch } from "../../../../hooks/query/product/useProuctsSearch";
import { useAppSelector } from "../../../../store";

const SKIP = 100;

export const ProductsView: React.FC<{ isVisible: boolean; weights: any }> = ({
  isVisible,
  weights,
}) => {
  const { searchTerm, filters, user } = useAppSelector(({ app }) => app);
  const { fetchNextPage, isFetchingNextPage, isLoading, data, hasNextPage } =
    useInfiniteProductSearch({
      term: searchTerm,
      filters,
      weights,
    });

  const products = useMemo(() => {
    if (!data) return [];
    return data?.pages.flatMap((products) => products);
  }, [data]);

  return (
    <>
      {/* {searchTerm && <WalkMeSearchResults />} */}
      <div className="paddingLg">
        <ProductsGrid
          products={products}
          fetchNextPage={fetchNextPage}
          isLoading={isLoading}
          hasNextPage={!!hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          // TODO:HORRIBLE
          invalidateKeyOnDislike={[
            "InfiniteProductsResults",
            searchTerm,
            filters,
            weights,
            undefined,
          ]}
        />
      </div>
    </>
  );
};
