import { Input, InputProps } from "../Input";
import s from "./TextField.module.scss";
// export const AppTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
//   "& .MuiOutlinedInput-root": {
//     "&.Mui-focused fieldset": {
//       border: "thin solid #00000015",
//     },
//     "&:not(.Mui-focused)": {
//       "&:hover fieldset": {
//         borderColor: "#00000015",
//       },
//     },
//     "& .MuiFormLabel-root-MuiInputLabel-root": {
//       transform: "translate(12px, 12px)",
//     },
//   },

//   "& .MuiOutlinedInput-input": {
//     padding: "14px 18px",
//     fontSize: "14px",
//     fontFamily: "DM Sans",
//     color: "#1F2029",
//     backgroundColor: "white",
//     borderRadius: "12px",
//     "&::placeholder": {
//       color: "#797979",
//       fontFamily: "DM Sans",
//       fontSize: "14px",
//       opacity: 0.8,
//     },
//     "&:hover": {
//       "& .MuiOutlinedInput-notchedOutline": {
//         borderColor: "#00000015",
//       },
//     },
//   },
//   "& .MuiOutlinedInput-notchedOutline": {
//     borderColor: "#00000015",
//     borderRadius: "12px",
//     "&.Mui-focused": {
//       borderColor: "#00000015",
//     },
//     "&:hover": {
//       borderColor: "#00000015",
//     },
//   },
//   "& .MuiFormLabel-root": {
//     color: "#191a1b",
//     "&.Mui-focused": {
//       color: "#C13584",
//     },
//   },
// }));

export const AppTextField: React.FC<
  Omit<InputProps, "onChange"> & {
    onChange: (ev: { target: { value: string } }) => void;
  }
> = (props) => {
  // TODO: TEMP CHANGE

  const onChangeAdapter = (value: string) => {
    props.onChange({ target: { value } });
  };
  return (
    <Input
      containerClassName={`${s.container} ${props.containerClassName}`}
      theme="light"
      {...props}
      onChange={onChangeAdapter}
    />
  );
};
