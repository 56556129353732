import { useQuery } from "react-query";
import { getProductFilters } from "../../../services/api/products/products.api";
import {
  getWizardBrands,
  getWizardSuggestedBrands,
} from "../../../services/api/users/wizard/wizard.api";

export const useProductFeatureFilters = () =>
  useQuery({
    queryFn: async () => {
      const res = await getProductFilters();
      return res.items;
    },
    cacheTime: 1000 * 60 * 10,
    staleTime: 1000 * 60 * 10,
    queryKey: ["ProductFilters"],
  });

export const useWizardBrands = (term = "") =>
  useQuery({
    queryFn: async () => {
      const res = await getWizardBrands(term);
      return res.items;
    },
    enabled: term?.length > 1 || !term?.length,
    queryKey: ["WizardBrands", term],
    keepPreviousData: true,
  });
export const useWizardSuggestedBrands = (id?: string) =>
  useQuery({
    queryFn: async () => {
      const res = await getWizardSuggestedBrands({ id: id!, limit: 15 });
      return res.items;
    },
    // keepPreviousData: true,
    queryKey: ["WizardBrandsSuggestion", id],
    enabled: !!id,
  });
