import { Carousel } from "../../../../components/carousels/Carousel";
import { useViewCaller } from "../../../../hooks/ui/useWindowCaller";
import { COLLECTIONS } from "../collections/consts";
import { ViewPreviewTemplate } from "./previewTemaplate/PreviewTemplate";

export const CollectionsPreview: React.FC<{ onOpenView: () => void }> = ({
  onOpenView,
}) => {
  const [callView] = useViewCaller();

  return (
    <ViewPreviewTemplate
      cta="See more collections"
      onClickCta={onOpenView}
      title="Curated For You"
    >
      <Carousel
        settings={{ perView: 1.2, enablePagination: false }}
        items={COLLECTIONS.slice(4).map((c) => ({
          title: c.title,
          element: <Preview url={c.thumbnail} />,
          id: c.title,
        }))}
        onClickItem={(id) =>
          callView({
            id: "CollectionView",
            data: COLLECTIONS.find(({ title }) => title === id) ?? ({} as any),
          })
        }
      />
    </ViewPreviewTemplate>
  );
};

const Preview: React.FC<{ url: string }> = ({ url }) => {
  return (
    <div style={{ padding: "0 16px" }} className="flex justifyCenter">
      <div>
        <img
          src={url}
          alt=""
          style={{
            borderRadius: "6px",
            aspectRatio: 9 / 10,
            objectFit: "cover",
          }}
        />
      </div>
    </div>
  );
};
