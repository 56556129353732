import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import CheckroomRoundedIcon from "@mui/icons-material/CheckroomRounded";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import LocalFloristRoundedIcon from "@mui/icons-material/LocalFloristRounded";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PaletteOutlinedIcon from "@mui/icons-material/PaletteOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import SpaOutlinedIcon from "@mui/icons-material/SpaOutlined";
import TextureOutlinedIcon from "@mui/icons-material/TextureOutlined";
import { capitalize } from "lodash";
import { ReactNode, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { User } from "../../@types/entities/user";
import { Button } from "../../components/button/Button";
import { SlidingWindow } from "../../components/slidingWindow/SlidingWindow";
import { useSnackbar } from "../../contexts/snackbar";
import { useEditUserSettings } from "../../hooks/query/users/useUsers";
import { useDelayedClose } from "../../hooks/ui/useDelayedClose";
import { deleteAccount, logout } from "../../services/api/users/users.api";
import { useAppSelector } from "../../store";
import { ReactComponent as Size } from "../../styles/icons/Personalize.svg";
import s from "./Profile.module.scss";
import { AccountSettings } from "./windows/AccountSettings";
import { BrandsSettings } from "./windows/BrandsSettings";
import { ColorsSettings } from "./windows/ColorsSettings";
import { PriceSettings } from "./windows/PriceSettings";

export interface SettingsWindow {
  data: User;
  onChange: (partialForm: Partial<User>) => void;
}

type SettingsWindowName =
  | "account"
  | "brands"
  | "colors"
  | "fabric"
  | "style"
  | "pattern"
  | "location"
  | "price"
  | "size"
  | "thrift"
  | "eco";

export const UserSettings: React.FC<{ userId?: string }> = ({ userId }) => {
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const user = useAppSelector((store) => store.app.user);
  const [localForm, setLocalForm] = useState(user);
  const { onOpen, onClose, isOpen, data } = useDelayedClose<{
    name: SettingsWindowName;
  }>();
  const { mutateAsync: editUser, isLoading } = useEditUserSettings();
  if (!user) return <></>;

  const onLogout = async () => {
    await logout();
    navigate("/");
  };

  const onDeleteAccount = async () => {
    await deleteAccount();
    navigate("/");
  };

  const onChangeForm = (partial: Partial<User>) => {
    setLocalForm((form) => ({ ...form!, ...partial }));
  };

  const onCloseSettingsWindow = () => {
    onClose();
    setLocalForm(user);
  };

  const onSubmitEdit = async () => {
    await editUser(localForm!);
    onClose();
    openSnackbar(<>Profile updated.</>);
  };

  return (
    <>
      <div className="flex1 column gapXlg">
        <div className={s.section}>
          <div className={s.items}>
            <SettingsItem
              text="User settings"
              icon={<SettingsOutlinedIcon fontSize="inherit" />}
              onClick={() => onOpen({ name: "account" })}
            />
          </div>
        </div>
        <div className={s.section}>
          <div className={s.sectionTitle}>Style</div>
          <div className={s.items}>
            <SettingsItem
              text="brands"
              icon={<ShoppingBagOutlinedIcon fontSize="inherit" />}
              onClick={() => onOpen({ name: "brands" })}
            />
            <SettingsItem
              text="colors"
              icon={<PaletteOutlinedIcon fontSize="inherit" />}
              onClick={() => onOpen({ name: "colors" })}
            />
            <SettingsItem
              text="fabrics"
              icon={<TextureOutlinedIcon fontSize="inherit" />}
              onClick={() => onOpen({ name: "fabric" })}
              comingSoon
            />
            <SettingsItem
              text="Styles"
              icon={<CheckroomRoundedIcon fontSize="inherit" />}
              onClick={() => onOpen({ name: "style" })}
              comingSoon
            />
            <SettingsItem
              text="patterns"
              icon={<LocalFloristRoundedIcon fontSize="inherit" />}
              onClick={() => onOpen({ name: "pattern" })}
              comingSoon
            />
          </div>
        </div>
        <div className={s.section}>
          <div className={s.sectionTitle}>Shopping preferences</div>
          <div className={s.items}>
            <SettingsItem
              text="location"
              icon={<FmdGoodOutlinedIcon fontSize="inherit" />}
              onClick={() => onOpen({ name: "location" })}
              comingSoon
            />
            <SettingsItem
              text="Typical Price range"
              icon={<AttachMoneyOutlinedIcon fontSize="inherit" />}
              onClick={() => onOpen({ name: "price" })}
            />
            <SettingsItem
              text="Sizes"
              icon={
                <div className="icon withFill dark">
                  <Size width={12} height={12} />
                </div>
              }
              onClick={() => onOpen({ name: "size" })}
              comingSoon
            />
            <SettingsItem
              text="ethically sourced"
              icon={<SpaOutlinedIcon fontSize="inherit" />}
              onClick={() => onOpen({ name: "eco" })}
              comingSoon
            />
            <SettingsItem
              text="Previously owned"
              icon={<RefreshOutlinedIcon fontSize="inherit" />}
              onClick={() => onOpen({ name: "thrift" })}
              comingSoon
            />
          </div>
        </div>

        <div className={s.section}>
          <div className={s.sectionTitle}>Support</div>
          <div className={s.items}>
            <div className={`${s.item}`}>
              <div
                style={{ textTransform: "lowercase" }}
                className="column gapLg"
              >
                <div>
                  <Link
                    style={{ color: "#56a4ff" }}
                    to="mailto:raphael@gigifashion.ai"
                  >
                    raphael@gigifashion.ai
                  </Link>
                </div>
                <div>
                  <Link style={{ color: "#56a4ff" }} to="tel:+17183007851">
                    +1 718 300 7851
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={s.section}>
          <div className={s.sectionTitle}>Account</div>
          <div className={s.items}>
            <div className={`${s.item} alignCenter gapMd`} onClick={onLogout}>
              <div className="alignCenter gapMd">
                <LogoutOutlinedIcon fontSize="inherit" />
                Log out
              </div>
            </div>

            <div
              className={`${s.item}   ${s.delete}`}
              onClick={onDeleteAccount}
            >
              <div className="alignCenter gapMd">
                <DeleteOutlineOutlinedIcon fontSize="inherit" /> Delete account
              </div>
            </div>
          </div>
        </div>
      </div>
      <SlidingWindow
        isOpen={isOpen}
        onClose={() => onCloseSettingsWindow()}
        title={capitalize(data?.name)}
        direction="right"
      >
        <div className="flex1 column paddingLg overflowScroll">
          <DynamicSettingsContent
            type={data?.name}
            onChange={onChangeForm}
            data={localForm!}
          />
        </div>
        <div className="column paddingMd">
          <Button isLoading={isLoading} onClick={() => onSubmitEdit()}>
            Save Changes
          </Button>
        </div>
      </SlidingWindow>
    </>
  );
};

const DynamicSettingsContent: React.FC<
  SettingsWindow & { type?: SettingsWindowName }
> = ({ type, ...props }) => {
  switch (type) {
    case "account":
      return <AccountSettings {...props} />;
    case "brands":
      return <BrandsSettings {...props} />;
    case "colors":
      return <ColorsSettings {...props} />;
    case "price":
      return <PriceSettings {...props} />;
    default:
      return <></>;
  }
};

const SettingsItem: React.FC<{
  icon: ReactNode;
  text: string;
  onClick: () => void;
  comingSoon?: boolean;
}> = ({ text, icon, onClick, comingSoon }) => {
  return (
    <div onClick={comingSoon ? undefined : onClick} className={`${s.item}`}>
      <div className="alignCenter gapMd">
        {icon}
        {text}
      </div>
      <div
        style={{ fontSize: "14px" }}
        className="justifyCenter alignCenter flex"
      >
        {comingSoon ? (
          <span
            style={{
              fontSize: "12px",
              fontStyle: "italic",
              color: "#a9a9a9",
              fontWeight: "300",
            }}
          >
            Coming soon
          </span>
        ) : (
          <ArrowForwardIosRoundedIcon fontSize="inherit" />
        )}
      </div>
    </div>
  );
};
