import { find, pick } from "lodash";
import { Loader } from "../../../../components/loader/Loader";
import usePreloadImages from "../../../../hooks/ui/usePreloadImages";
import { useViewCaller } from "../../../../hooks/ui/useWindowCaller";
import { Collection } from "../collections/types";

const CATEGORIES: Collection[] = [
  {
    title: "Bodycon",
    payload: {
      term: "Bodycon dress",
    },
    thumbnail: getImg("bodycon"),
  },
  {
    title: "Slip",
    payload: {
      term: "slip dress",
    },
    thumbnail: getImg("slip"),
  },
  {
    title: "Beach",
    payload: {
      term: "cover up beach dress",
    },
    thumbnail: getImg("beach"),
  },
  {
    title: "Sweater",
    payload: {
      term: "sweater dress",
      filters: [{ key: "sleeve_length", value: ["long"] }],
    },
    thumbnail: getImg("sweater"),
  },
  {
    title: "Denim",
    payload: {
      term: "Denim fabric dress",
    },
    thumbnail: getImg("denim"),
  },
  {
    title: "Velvet",
    payload: {
      term: "Velvet fabric dress",
    },
    thumbnail: getImg("velvet"),
  },
  {
    title: "Sequin",
    payload: {
      term: "Sequin dress",
    },
    thumbnail: getImg("sequin"),
  },
  {
    title: "Mini Black",
    payload: {
      term: "Mini black dress",
    },
    thumbnail: getImg("mini black"),
  },
  {
    title: "Kaftan",
    payload: {
      term: "kaftan dress",
      filters: [
        { key: "length", value: ["maxi", "midi", "floor"] },
        { key: "sleeve_length", value: ["long"] },
      ],
    },
    thumbnail: getImg("bodycon"),
  },
  {
    title: "Blazer",
    payload: {
      term: "Blazer dress",
    },
    thumbnail: getImg("blazer dress"),
  },
  {
    title: "Plunge",
    payload: {
      term: "deep neckline plunge dress",
    },
    thumbnail: getImg("plunge"),
  },
  {
    title: "Wrap",
    payload: {
      term: "Wrap dress",
    },
    thumbnail: getImg("wrap"),
  },
  {
    title: "Floral",
    payload: {
      term: "Floral dress",
    },
    thumbnail: getImg("floral"),
  },
  {
    title: "Striped",
    payload: {
      term: "Striped dress",
    },
    thumbnail: getImg("striped"),
  },
  {
    title: "Polka Dot",
    payload: {
      term: "polka dot dress",
    },
    thumbnail: getImg("polka dot"),
  },
];

export const CategoriesView = () => {
  const [callWindow] = useViewCaller();
  return (
    <GroupsLayout
      title={"Categories"}
      groups={CATEGORIES.map((c) => ({ src: c.thumbnail, name: c.title }))}
      onClickGroup={(id: string) =>
        callWindow({
          id: "CollectionView",
          data: pick(
            find(CATEGORIES, ({ title }) => title === id),
            "title",
            "payload"
          ) as any,
        })
      }
    />
  );
};

export const GroupsLayout: React.FC<{
  title?: string;
  groups: { name: string; src: string; id?: string }[];
  onClickGroup: (id: string) => void;
}> = ({ groups, title, onClickGroup }) => {
  const [didLoad] = usePreloadImages(groups.map(({ src }) => src));
  if (!didLoad) return <Loader />;
  return (
    <div className="column gapLg ">
      {title && <h3 className="justifyCenter">{title}</h3>}
      <div
        style={{
          gap: "60px 16px",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        {groups.map((g) => (
          <div
            className="column gapLg"
            onClick={() => onClickGroup(g.id ?? g.name)}
          >
            <div className="alignCenter">
              <img
                src={g.src}
                style={{ borderRadius: "6px", aspectRatio: 3 / 4 }}
              />
            </div>
            <h4 className="justifyCenter">{g.name}</h4>
          </div>
        ))}
      </div>
    </div>
  );
};

function getImg(c: string) {
  return `https://storage.googleapis.com/gigi_pics_public/categories/${c}.jpg`;
}
