import { BrandSuggestion } from "../../../../@types/entities/brand";
import { Carousel } from "../../../../components/carousels/Carousel";
import { Image } from "../../../../components/image/Img";
import { ProductsGrid } from "../../../../components/ProductsGrid/ProductsGrid";
import { SlidingWindow } from "../../../../components/slidingWindow/SlidingWindow";
import { useInfiniteProductSearch } from "../../../../hooks/query/product/useProuctsSearch";
import { useFlattenInfiniteQuery } from "../../../../hooks/query/useFlattenInfinite";
import { useDelayedClose } from "../../../../hooks/ui/useDelayedClose";
import s from "./HomeView.module.scss";
import { ViewPreviewTemplate } from "./previewTemaplate/PreviewTemplate";

export const SuggestedBrands: React.FC<{
  brands: BrandSuggestion[];
  onOpenView: () => void;
}> = ({ brands, onOpenView }) => {
  // const [windowData, setWindowData] = useState<SlidingWindowData | null>(null);
  const { onOpen, onClose, isOpen, data: windowData } = useDelayedClose();
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteProductSearch(
      { filters: [{ key: "brand", value: windowData?.data }], limit: 25 },
      !!windowData?.data
    );
  const products = useFlattenInfiniteQuery(data);
  return (
    <>
      <ViewPreviewTemplate
        title="Brands For You"
        cta="See more brands"
        onClickCta={onOpenView}
      >
        <Carousel
          settings={{ enablePagination: true }}
          onClickItem={(id) => onOpen({ name: id, data: id })}
          items={brands.slice(0, 4).map((b) => ({
            title: b.name,
            id: b.name,
            element: (
              <div className="gapMd alignCenter paddingMd">
                {b.products.slice(0, 2).map((p) => (
                  <div className="flex1 alignCenter justifyCenter">
                    <img
                      style={{ aspectRatio: 2 / 3, objectFit: "cover" }}
                      src={p.imgUrls[0]}
                    />
                  </div>
                ))}
              </div>
            ),
          }))}
        />
      </ViewPreviewTemplate>

      <SlidingWindow
        isOpen={isOpen}
        onClose={() => onClose()}
        title={windowData?.name}
        direction="right"
      >
        <div className="sideMargin">
          <ProductsGrid
            products={products}
            isLoading={isLoading}
            isFetchingNextPage={!!isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            hasNextPage={!!hasNextPage}
          />
        </div>
      </SlidingWindow>
    </>
  );
};

export const BrandPreview: React.FC<{
  brand: BrandSuggestion;
  onOpen: (data: { name: string; data: string }) => void;
}> = ({ brand, onOpen }) => {
  return (
    <div className="column gapMd">
      <div
        onClick={() => onOpen({ name: brand.name, data: brand._id })}
        className="column gapLg"
      >
        <div style={{ position: "relative" }} className={s.brandProducts}>
          {brand.products.slice(0, 6).map((p) => (
            <div className="flex1">
              <Image
                fallback={p.parsing_path ?? p.feature_parse_path ?? p.seg_path}
                src={p.imgUrls[0]}
                alt="product"
              />
            </div>
          ))}
        </div>
        {/* <div className={s.brandBanner}>{b.name}</div> */}
      </div>
    </div>
  );
};
