import { useState } from "react";
import { List } from "../../../../@types/entities/list";
import { GigiTitle } from "../../../../components/gigiTitle/GigiTitle";
import { Loader } from "../../../../components/loader/Loader";
import { SlidingWindow } from "../../../../components/slidingWindow/SlidingWindow";
import usePreloadImages from "../../../../hooks/ui/usePreloadImages";
import { socketService } from "../../../../services/mock/socket";
import { ListDetails } from "../../../lists/cmps/listDetails/ListDetails";
import s from "./CollectionsView.module.scss";
import { COLLECTIONS } from "./consts";

export const CollectionsView: React.FC = () => {
  const [selectedList, setSelectedList] = useState<List | null>(null);
  const [didLoad] = usePreloadImages(COLLECTIONS.map((c) => c.thumbnail));

  if (!didLoad) return <Loader />;
  return (
    <>
      <div className={s.collectionsContainer}>
        <GigiTitle
          talkToGigi
          title="Curated For You"
          description="Gigi Monitors your activity and curates collections for you based on you
        style and preferences."
        />
        <div className={s.categories}>
          {COLLECTIONS.map((collection) => (
            <div
              className={s.category}
              onClick={() =>
                socketService.emit("windowOpen", "appWindow", {
                  id: "CollectionView",
                  data: {
                    title: collection.title,
                    payload: { ...collection.payload, tags: [] },
                  },
                })
              }
            >
              <div className="alignCenter">
                <img src={collection.thumbnail} alt="" />
              </div>
              <h3 className={s.title}>{collection.title}</h3>
            </div>
          ))}
        </div>
      </div>

      <SlidingWindow
        isOpen={!!selectedList}
        onClose={() => setSelectedList(null)}
        title={`${selectedList?.title} list`}
        direction="right"
      >
        <ListDetails list={selectedList} initialTab={"suggested"} />
      </SlidingWindow>
    </>
  );
};
