import { noop } from "lodash";
import React, { useState } from "react";
import { ProductDetails } from "../../../@types/entities/product.types";
import { ProductsGrid } from "../../../components/ProductsGrid/ProductsGrid";
import { ProductSkeleton } from "../../../components/skeleton/ProductSkeleton";
import { SlidingWindow } from "../../../components/slidingWindow/SlidingWindow";
import { LookbookWindowInterface } from "../../../components/slidingWindow/types/app/types";
import {
  useLookalikeProducts,
  useLookbookDetails,
} from "../../../hooks/query/lookbook/useLookbook";
import { useDelayedClose } from "../../../hooks/ui/useDelayedClose";
import { BaseLookalikeProducts } from "../../Lookalike/LookalikeProducts";
import s from "./LookbookDetails.module.scss";

export interface Lookbook {
  _id: string;
  code: string;
  name: string;
  description: string;
  mainImg: string;
  thumbnail: string;
  product_ids: string[];
}

export interface LookbookDetails extends Lookbook {
  products: ProductDetails[];
  lookalike: { path: string; _id: string }[];
}

export const LookbookWindow: React.FC<{
  data: LookbookWindowInterface["data"];
  onClose: () => void;
  isOpen: boolean;
}> = ({ data, onClose, isOpen }) => {
  return (
    <SlidingWindow
      onClose={() => onClose()}
      direction="right"
      title={""}
      isOpen={isOpen && !!data}
      keepMounted={false}
    >
      <LookbookDetailsPage lookbook={data.lookbook!} />
    </SlidingWindow>
  );
};

export const LookbookDetailsPage: React.FC<{ lookbook: Lookbook }> = ({
  lookbook,
}) => {
  const { data, isLoading } = useLookbookDetails(lookbook?._id);
  const [tabs, setTabs] = useState<"products" | "lookbook">("lookbook");
  const { onOpen, onClose, data: lookalikeData, isOpen } = useDelayedClose();
  const { data: products, isLoading: isLoadingProducts } = useLookalikeProducts(
    lookalikeData?._id
  );

  if (!lookbook) return null;

  return (
    <div className={s.container}>
      <div className={s.mainImg}>
        <img src={lookbook.mainImg} alt="lookbook" />
      </div>
      <div className={s.contentContainer}>
        <div className={s.description}>
          <h1>{lookbook.name}</h1>
          <div>{lookbook.description}</div>
        </div>
        <div className={s.tabsContainer}>
          <div className={s.tabs}>
            <div
              onClick={() => setTabs("products")}
              className={`${s.tab} ${tabs === "products" ? s.selected : ""}`}
            >
              Products
            </div>
            <div
              onClick={() => setTabs("lookbook")}
              className={`${s.tab}  ${tabs === "lookbook" ? s.selected : ""}`}
            >
              Lookbook
            </div>
          </div>
          {tabs === "lookbook" && (
            <div className={s.lookbook}>
              {isLoading && <ProductSkeleton sumVersion repeat={4} />}
              {data?.lookalike.map((item) => (
                <div className={s.item} onClick={() => onOpen(item)}>
                  <img className={s.img} src={item.path} alt="lookbook img" />
                </div>
              ))}
            </div>
          )}
          {tabs === "products" && (
            <ProductsGrid
              products={data?.products ?? []}
              isLoading={isLoading}
              fetchNextPage={noop}
              isFetchingNextPage={false}
              hasNextPage={false}
            />
          )}
        </div>
      </div>
      <SlidingWindow onClose={onClose} direction="right" isOpen={!!isOpen}>
        <BaseLookalikeProducts
          onClose={onClose}
          lookalikeImg={lookalikeData?.path}
          products={products?.items ?? []}
          isLoading={isLoadingProducts}
        />
      </SlidingWindow>
    </div>
  );
};
