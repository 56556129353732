import { RouteObject, createBrowserRouter } from "react-router-dom";
import App from "./App";
import { InstagramApp } from "./hooks/admin/InstagramApp/InstagramApp";
import { Admin } from "./pages/admin/Admin";
import { LookalikesParsing } from "./pages/admin/lookalikes/LookalikesParsing";
import { ProductParsing } from "./pages/admin/prroductParsing/ProductParsing";
import { HomePage } from "./pages/home/HomePage";
import { LandingV2 } from "./pages/landing/landingV2";
import { ListsPage } from "./pages/lists/Lists";
import { Login } from "./pages/login/Login";
import { DetectionPage } from "./pages/Lookalike/Detections/DetectionPage";
import { LookalikePage } from "./pages/Lookalike/LookalikePage";
import { OnboardPage } from "./pages/onboarding/OnboardPage";
import { ProfilePage } from "./pages/profile/Profile";
import { ResetPassword } from "./pages/resetPassword/ResetPassword";
import { SearchPage } from "./pages/search/SearchPage";
import { SupportPage } from "./pages/support/SupportPage";
import { WizardPage } from "./pages/wizard/Wizard";
import { Rerouted, withAdminGuard, withGaurd } from "./utils/withGuard";

const routes: RouteObject[] = [
  {
    Component: App,
    path: "/",
    children: [
      {
        Component: withGaurd(HomePage),
        path: "home",
      },
      {
        Component: withGaurd(SearchPage),
        path: "search",
      },
      {
        Component: withGaurd(ListsPage),
        path: "lists",
      },
      {
        Component: OnboardPage,
        path: "onboard",
      },
      {
        Component: Login,
        path: "login",
      },
      {
        Component: SupportPage,
        path: "support",
      },
      {
        Component: LandingV2,
        path: "",
      },
      {
        Component: ResetPassword,
        path: "reset-password/:token",
      },
      {
        Component: withGaurd(ProfilePage),
        path: "profile",
      },
      {
        Component: withGaurd(WizardPage),
        path: "wizard",
      },
    ],
  },
  {
    path: "/lookalikes/:parseId",
    Component: LookalikePage,
  },
  {
    path: "/detections/:sourceId",
    Component: DetectionPage,
  },
  {
    Component: withAdminGuard(Admin),
    path: "admin",
    children: [
      { Component: withAdminGuard(LookalikesParsing), path: "lookalikes" },
      { Component: withAdminGuard(ProductParsing), path: "product-parsing" },
      { Component: withAdminGuard(InstagramApp), path: "instagram-app" },
    ],
  },
  { path: "*", Component: Rerouted },
];

export const router = createBrowserRouter(routes);
