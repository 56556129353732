import { ReactNode } from "react";
interface StyleSettingsTemplateProps {
  likedTitle: string;
  likedItems: ReactNode;
  dislikedTitle: string;
  dislikedItems: ReactNode;
  selectionOptionsElement: ReactNode;
  onOpen: (data: { name: string }) => void;
}

export const StyleSettingsTemplate: React.FC<StyleSettingsTemplateProps> = ({
  likedTitle,
  likedItems,
  dislikedTitle,
  dislikedItems,
  selectionOptionsElement,
  onOpen,
}) => {
  return (
    <div className="column gapXlg">
      <div className="gapLg column">
        <div className="alignCenter spaceBetween">
          <h4>{likedTitle}</h4>
          <span
            onClick={() => onOpen({ name: likedTitle })}
            style={{ fontWeight: "500", color: "#0770cf", fontSize: "14px" }}
          >
            + Add
          </span>
        </div>

        {likedItems}
      </div>
      <div className="column gapLg">
        <div className="alignCenter spaceBetween">
          <h4>{dislikedTitle}</h4>

          <span
            style={{ fontWeight: "500", color: "#0770cf", fontSize: "14px" }}
            onClick={() => onOpen({ name: dislikedTitle })}
          >
            + Add
          </span>
        </div>
        {dislikedItems}
      </div>
      {selectionOptionsElement}
    </div>
  );
};
