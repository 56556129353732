import { noop } from "lodash";
import { BrandsList } from "../../../components/filters/filterCategories/Brands/BrandsList";
import { SlidingWindow } from "../../../components/slidingWindow/SlidingWindow";
import { useDelayedClose } from "../../../hooks/ui/useDelayedClose";
import { toggleInList } from "../../../utils/array";
import { BrandContainer } from "../../onboarding/cmps/brandSelection/BrandSelection";
import { SettingsWindow } from "../UserSettings";
import { StyleSettingsTemplate } from "./StyleSettingsTemplate";
import s from "./WindowSettings.module.scss";
export const BrandsSettings: React.FC<SettingsWindow> = ({
  data,
  onChange,
}) => {
  const { onOpen, isOpen, data: localWindowData, onClose } = useDelayedClose();

  return (
    <StyleSettingsTemplate
      onOpen={onOpen}
      likedItems={
        <div className={s.gridPreview}>
          {data.brands?.map((b) => (
            <BrandContainer
              selected
              onClick={() =>
                onChange({ brands: toggleInList(data.brands!, b) })
              }
            >
              {b}
            </BrandContainer>
          ))}
        </div>
      }
      likedTitle="My Brands"
      dislikedItems={
        <div className={s.gridPreview}>
          {data.dislikedBrands?.map((b) => (
            <BrandContainer
              selected
              onClick={() =>
                onChange({ brands: toggleInList(data.dislikedBrands ?? [], b) })
              }
            >
              {b}
            </BrandContainer>
          ))}
        </div>
      }
      dislikedTitle="Disliked Brands"
      selectionOptionsElement={
        <SlidingWindow
          isOpen={isOpen}
          onClose={onClose}
          title={localWindowData?.name}
        >
          <BrandsList onSelect={noop} onClose={noop} selectedIds={[]} />
        </SlidingWindow>
      }
    />
  );
};
